.testimonials {
  padding: 4rem 0;

  .wrapper {
    max-width: 71.25rem;
    margin: 0 auto;
    padding: 0 1rem;

    .heading {
      margin-bottom: 4.5rem;
      font-family: 'Markazi Text', serif;
      font-size: 4rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-align: center;
    }

    .cardsContainer {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 2rem 1rem;
    }
  }
}
