@import 'styles/common';

.dropdown {
  position: relative;
  width: 100%;
  margin-top: -0.5rem;

  .icon {
    position: absolute;
    top: 1.25rem;
    left: 1rem;
  }

  .arrow {
    position: absolute;
    transform: scale(0.9);
    top: 1.35rem;
    right: 1rem;
  }

  select {
    width: 100%;
    margin-top: 0.625rem;
    padding: 0.5rem;
    background: $lightGrayishBlue;
    border: none;
    outline: none;
    border-radius: 0.5rem;
    text-align: center;
    font-family: 'Karla', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    color: $primaryGreen;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    .option {
      font-weight: 600;
    }
  }
}
