@import 'styles/common';

.reviewCard {
  @extend .flexCol;
  gap: 1.25rem;
  width: 13.25rem;
  padding: 1.5rem;
  border: 1px solid $lightGray;
  border-radius: 0.75rem;
  transition: all 0.2s;

  .starContainer {
    display: flex;
    gap: 0.25rem;
  }

  .userInfo {
    @extend .alignCen;
    gap: 1.25rem;

    img {
      border-radius: 0.25rem;
    }

    .name {
      font-family: 'Karla', sans-serif;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .review {
    font-family: 'Karla', sans-serif;
  }

  &:hover {
    box-shadow: 0.25rem 0.25rem 0.5rem $lightGray;
  }
}
