@import 'styles/common';

.bookingDetails {
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  font-family: 'Karla', sans-serif;
  font-size: 1.25rem;

  &,
  td,
  th {
    border: 1px solid $lightGray;
    border-collapse: collapse;
    text-align: center;
  }

  caption {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    color: $primaryGreen;
  }

  th,
  td {
    transition: all 0.2s;
    padding: 0.75rem;
  }

  td:hover {
    background: $primaryGreen;
    color: white;
  }
}
