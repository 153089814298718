@import 'styles/common';

.dialogBox {
  @extend .flexColCen;
  gap: 1.25rem;
  width: 100%;
  max-width: 31.25rem;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  border: none;
  box-shadow: 0.5rem 0.5rem 1rem #666;
  z-index: 10;

  .title {
    font-family: 'Markazi Text', serif;
    font-size: 2.5rem;
    text-align: center;
    line-height: 0.8;
  }

  .msg {
    font-family: 'Karla', sans-serif;
    font-size: 1.25rem;
    text-align: center;
  }
}
