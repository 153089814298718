@import 'styles/common';

.heroSection {
  background: $primaryGreen;
  padding: 2.75rem 0;

  .wrapper {
    position: relative;
    display: flex;
    gap: 3rem;
    max-width: 62.5rem;
    margin: 0 auto;
    padding: 0 1rem;

    article {
      @extend .flexCol;
      gap: 2.75rem;

      h1 {
        font-family: 'Markazi Text', serif;
        font-size: 4rem;
        letter-spacing: 1.5px;
        color: $primaryYellow;
      }

      h4 {
        margin-top: -0.5rem;
        font-family: 'Markazi Text', serif;
        font-size: 2rem;
        letter-spacing: 1.5px;
        color: white;
      }

      p {
        font-family: 'Karla', sans-serif;
        font-size: 1.25rem;
        color: white;
        max-width: 23rem;
      }
    }

    .imgContainer {
      max-width: 25rem;
      position: absolute;
      right: 1rem;

      img {
        width: 100%;
        border-radius: 1rem;
      }
    }
  }
}

@media screen and (max-width: 50rem) {
  .heroSection .wrapper {
    flex-direction: column;
    align-items: center !important;

    article {
      align-items: center;
      width: 100%;
      max-width: 43.75rem;

      div,
      p {
        text-align: center;
      }

      p {
        max-width: 30rem;
      }
    }

    .imgContainer {
      position: static;
      max-width: 43.75rem;
    }
  }
}

@media screen and (max-width: 34.375rem) {
  .heroSection .wrapper article {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 25rem) {
  .heroSection .wrapper article div {
    h1 {
      line-height: 0.8;
    }

    h4 {
      margin-top: 0;
    }
  }
}
