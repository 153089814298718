@import 'styles/common';

.bookingForm {
  padding: 4rem 1rem;

  .wrapper {
    max-width: 55rem;
    margin: 0 auto;

    form {
      max-width: 32.5rem;
      margin: 0 auto;

      label,
      input,
      textarea {
        margin-bottom: 0.75rem;
        font-family: 'Karla', sans-serif;
        font-size: 1.25rem;
      }
    }

    .btnContainer {
      @extend .justifyEven;
      flex-wrap: wrap;
      gap: 1rem 2rem;
      margin-top: 2rem;
    }
  }
}

.reservationDetails,
.contactInfo,
.paymentDetails,
.reviewDetails {
  margin-top: 5rem;

  .container {
    @extend .flexCol;
    gap: 1rem;
    margin-top: 3rem;

    .field {
      position: relative;
      @extend .flexCol;

      input,
      textarea {
        padding: 0.75rem 1rem;
        border: 1px solid $lightGray;
        border-radius: 0.5rem;

        &:focus {
          outline-color: $primaryGreen;
        }
      }

      textarea {
        height: 175px;
        resize: vertical;
      }

      .radioContainer {
        @extend .alignCen;

        span {
          @extend .alignCen;
          gap: 1rem;
          width: 50%;

          label {
            @extend .alignCen;
            gap: 1rem;
          }
        }

        .errMsg {
          top: unset;
          bottom: 0.5rem !important;
        }
      }

      .input {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      .dropdownErr {
        top: 4.9rem;
      }

      .icon {
        position: absolute;
        right: 0.75rem;
        bottom: 1.25rem;
      }
    }

    .numOfGuests {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .counter {
        @extend .alignCen;
        gap: 0.75rem;
        margin-bottom: 0.75rem;
        font-family: 'Karla', sans-serif;

        .btn {
          @extend .flexCenter;
          width: 1rem;
          padding: 0 0.25rem;
          font-size: 1.75rem;
          border-radius: 0.25rem;
          cursor: pointer;
          transition: all 0.2s;
          line-height: 0.8;
          user-select: none;

          &:hover {
            border-color: $lightGray;
          }

          &:active {
            transform: scale(0.8);
          }
        }

        .num {
          min-width: 1rem;
          padding: 0 0.5rem;
          border: 1px solid $lightGray;
          border-radius: 0.25rem;
          text-align: center;
          font-size: 1.5rem;
        }
      }

      .errMsg {
        top: 2.1rem !important;
      }
    }
  }

  .detailsContainer {
    @extend .flexCol;
    gap: 0.5rem;
    margin-top: 3rem;

    .item {
      display: flex;
      gap: 2rem;
      font-family: 'Karla', sans-serif;

      .label {
        min-width: 200px;
        font-size: 1.25rem;
        font-weight: 600;
      }

      .value {
        font-size: 1.15rem;
        font-weight: 400;
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 31.25rem) {
  .reservationDetails .field .radioContainer {
    flex-direction: column;
    align-items: start !important;
  }

  .detailsContainer {
    gap: 1rem;

    .item {
      flex-direction: column;
      gap: 0.15rem !important;

      .label {
        font-weight: 700;
      }

      .value {
        margin-left: 1.5rem;
        text-align: center;
      }
    }
  }
}
