.booking {
  .heroSection > div {
    align-items: center;
  }
}

@media screen and (max-width: 53rem) {
  .booking .heroSection > div article p {
    max-width: 20rem;
  }
}

@media screen and (max-width: 50rem) {
  .booking .heroSection > div article p {
    max-width: 30rem;
  }
}
