$primaryGreen: #495e57;
$primaryYellow: #f4ce14;
$orange: #ee9972;
$lightGrayishBlue: #edeeef;
$lightGray: #ccc;

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alignCen {
  display: flex;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.justifyBetween {
  display: flex;
  justify-content: space-between;
}

.justifyEven {
  display: flex;
  justify-content: space-evenly;
}

.flexColCen {
  @extend .flexCenter;
  @extend .flexCol;
}
