@import 'styles/common';

.header {
  @extend .flexCenter;
  height: 6rem;
  border-bottom: 1px solid $lightGray;

  .wrapper {
    position: relative;
    @extend .alignCen;
    justify-content: space-between;
    width: 100%;
    max-width: 71.25rem;
    padding: 0 1rem;

    .container {
      display: flex;
      gap: 1.5rem;

      .actionBtns {
        @extend .alignCen;
        gap: 1rem;

        .basketIcon {
          @extend .flexCenter;
          min-width: 1.8rem;
          min-height: 1.8rem;
          padding: 0.25rem 0.15rem;
          border-radius: 0.5rem;
          transition: all 0.2s;

          &:hover {
            background: $primaryGreen;

            svg path {
              fill: $primaryYellow;
            }
          }
        }

        .menuBtn {
          @extend .flexCenter;
          width: 2rem;
          height: 2rem;
          background: transparent;
          border: 1px solid $lightGray;
          border-radius: 0.25rem;
          cursor: pointer;
          display: none;

          &:hover {
            background: $primaryGreen;

            svg path {
              stroke: $primaryYellow;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 55rem) {
  .header {
    height: 4.375rem;

    .menuBtn {
      display: flex !important;
    }
  }
}
