@import 'styles/common';

.confimedBooking {
  padding: 3rem 1rem;

  .header {
    @extend .flexCol;
    gap: 2rem;
    max-width: 62.5rem;
    margin: 0 auto 3rem;
    text-align: center;

    h2 {
      font-family: 'Markazi Text', serif;
      font-size: 3rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 0.8;
    }

    p {
      font-family: 'Karla', sans-serif;
      font-size: 1.25rem;
    }
  }

  .btnContainer {
    @extend .justifyEven;
    max-width: 50rem;
    margin: 3rem auto 0;
  }
}
